const buttonEl = document.getElementById('button_Melzofy');
// var melzofyDiv = document.getElementById('div-melzofy');
var url = 'https://script.google.com/macros/s/AKfycbyD61nzesntYZF70cvyQW8EGXzmi6-ah677Ejz71xWo679Jqhs/exec'
buttonEl.addEventListener('click', onSubmit);

function onSubmit(e) {
  e.preventDefault();
  // console.log("YOu r Win");

  // buttonEl.disabled = true
  var YourName = document.getElementById('contact-your-name').value;
  var EmailId = document.getElementById('contact-email-id').value;
  var Telephone = document.getElementById('contact-telephone').value;
  var Company = document.getElementById('contact-company').value;
  var Message = document.getElementById('contact-message').value;
  var melzAbout = document.getElementById('melzoabout').value;
  // var expo = 'melzo-about'
  var phoneno = /^\d{10}$/;

  if (YourName === "") {
    alert("Please enter Your Name !");
  } else if (!document.getElementById("contact-email-id").checkValidity()) {
    alert("Please enter your valid Email-id !");
  } else if (Telephone === "") {
    alert("Please enter 10 Digits(only) mobile number !");
  } else if (Company === "") {
    alert("Please enter Your Company !");
  } else if (Message === "") {
    alert("Please enter Your Message !");
  } else {
    if (!phoneno.test(Telephone)) {
      alert("Please enter 10 Digits(only) mobile number !");
    } else {
      document.getElementById("button_Melzofy").disabled = true;
      var obj = {
        yourname: YourName,
        emailid: EmailId,
        telephone: Telephone,
        company: Company,
        message: Message,
        inquiry: melzAbout
      };
      console.log("Fire obj :: ", obj);
      //   axios.post('https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendMail', obj, {
      axios.post(' https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendMail', obj, {
        headers: { // 'x-access-token': token
        }
      }).then(res => {

        //-------------Send Data on EXCEL----------------------

        var objExcel = {
          Date: new Date().toLocaleString('en-IN', hour12 = true),
          Name: YourName,
          Email: EmailId,
          Mobileno: Telephone,
          Company: Company,
          Message: Message,
          Inquiry: melzAbout
        };
        var jqxhr = $.ajax({
          url: url,
          method: "GET",
          dataType: "json",
          data: objExcel
        }).success(() => {
          // do something
          // alert("Done")
          YourName = document.getElementById('contact-your-name').value = "";
          EmailId = document.getElementById('contact-email-id').value = "";
          Telephone = document.getElementById('contact-telephone').value = "";
          Company = document.getElementById('contact-company').value = "";
          Message = document.getElementById('contact-message').value = "";
          // alert("Form submitted successfully")
          document.getElementById("button_Melzofy").disabled = false;
          location.href = "../thanks.html";
        }



        );



      })

      //   window.open("https://melzo.com/dekho/5e7d9d1313fe800010b86c61", '_blank');
    }
  }
}
(function ($) {
  $('#DemoBtn').on('click', function () {
    $('.demoposition').toggleClass('activedemo');
  });

})(jQuery);